
// 定数群
// スクリプト側との同期用
// TODO:データベースで二重定義解消検討

// Groupの一覧
// key: テーブルソート用のキー
// 表示時に順番保証するためにArrayを利用
export const GroupParams = [
  { key: 'gx0', value: '国内株式' },
  { key: 'gx1', value: '国内債券' },
  { key: 'gx2', value: '国内REIT' },
  { key: 'gx3', value: '海外株式' },
  { key: 'gx4', value: '海外債券' },
  { key: 'gx5', value: '海外REIT' },
  { key: 'gx6', value: 'バランス' },
  { key: 'gx7', value: 'コモディティ' },
  { key: 'gx8', value: 'その他' },
  { key: 'gx9', value: '元本確保型' }
]

// ソート時に無効化な値として扱う値
export const InvalidSortValue = '-'
