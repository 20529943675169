import React from 'react'
import { Link } from 'react-router-dom'
import { UseSortableData } from '../Util'
import { GroupParams } from '../Params'

// style
const thStyle = {
  minWidth: '70px'
}
const tdStyle = {
  whiteSpace: 'nowrap'
}
// item一覧を元に証券会社一覧を作成
const itemToCompanies = (props, headers) => {
  const companies = props.companies.map((company) => {
    const list = props.items.filter(x => x.company === company.name)
    const hash = {
      company_id: company.id,
      company: company.name,
      total: list.length
    }
    headers.forEach(header => {
      const key = header.key
      switch (key) {
        case 'company':
          hash[key] = company.name
          break
        case 'total':
          hash[key] = list.length
          break
        default:
          hash[key] = list.filter(x => x.group === header.value).length
          break
      }
    })
    return hash
  })
  return companies
}

export const Companies = (props) => {
  // ヘッダー
  const headers = [
    {
      key: 'company',
      value: '証券会社'
    },
    {
      key: 'total',
      value: '銘柄総数'
    },
    ...GroupParams
  ]

  const { items, requestSort } = UseSortableData(
    itemToCompanies(props, headers)
  )

  return (
    <>
      <header className="main">
        <h1>iDeCo証券会社一覧</h1>
      </header>
      <hr className="major" />
      <table>
      <thead>
        <tr>
        {
          headers.map((param) => {
            return (
              <th key={param.key} onClick={() => requestSort(param.key)} style={thStyle}>
                {param.value}<i className="fa fa-sort"></i>
              </th>
            )
          })
        }
        </tr>
      </thead>
      <tbody>
      {
        items.map((item) => {
          return (
            <tr key={item.company_id}>
              {
                headers.map((header) => {
                  const name = item[header.key]
                  if (header.key === 'company') {
                    return (
                      <td key={header.key} style={tdStyle}>
                        <Link to={'/?c=' + item.company_id}>{name}</Link>
                      </td>
                    )
                  } else {
                    return (
                      <td key={header.key} style={tdStyle}>{name}</td>
                    )
                  }
                })
              }
            </tr>
          )
        })
      }
      </tbody>
      </table>
    </>
  )
}
