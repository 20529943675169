import React from 'react'
import { Link } from 'react-router-dom'
import { UseSortableData } from '../Util'
import { InvalidSortValue } from '../Params'
import { URLQuery } from '../module/URLQuery'
import { GroupChart } from './GroupChart'

// th
const thStyle = {
  minWidth: '70px'
}
// name
const nameStyle = {
  textAlign: 'left',
  paddingLeft: '5px'
}
// cost
const costStyle = {
  textAlign: 'right'
}
// company
const companyStyle = {
  textAlign: 'right',
  whiteSpace: 'nowrap',
  paddingRight: '5px'

}
// default
const defaultStyle = {
  textAlign: 'center',
  whiteSpace: 'nowrap'
}

const filterItems = (baseItems, company, filter) => {
  let items = baseItems
  // 会社名でフィルター
  if (company !== undefined) {
    items = items.filter(x => x.company === company.name)
  }
  // 分類別にフィルター
  const domesticGroup = ['国内株式', '国内債券', '国内REIT']
  const principalProtection = ['元本確保型']
  switch (filter) {
    case 'r1':
      items = items.filter(x => domesticGroup.includes(x.group))
      break
    case 'r2':
      items = items.filter(x =>
        !domesticGroup.includes(x.group) &&
        !principalProtection.includes(x.group))
      break
    case 'r3':
      items = items.filter(x => principalProtection.includes(x.group))
      break
    default:
      break
  }
  return items
}
const customFilterLink = (filter, count, urlQuery) => {
  const filterToStr = {
    r1: '国内型',
    r2: '海外型',
    r3: '元本確保型'
  }
  let name = null
  switch (filter) {
    case null:
      name = '全て'
      break
    default:
      name = filterToStr[filter]
      break
  }
  const url = urlQuery.url(filter, urlQuery.queryC)
  const text = `${name}(${count}件)`
  const customLinkStyle = {
    marginRight: '10px'
  }
  if (filter === urlQuery.queryF) {
    return (
      <div key={url} style={customLinkStyle}>
        {text}
      </div>
    )
  } else {
    return (
      <div key={url} style={customLinkStyle}>
        <Link to={url}>{text}</Link>
      </div>
    )
  }
}

const itemLink = (name, url) => {
  // 個別ページへのURLが存在しない銘柄もある
  if (url === '') {
    return <>{ name }</>
  } else {
    return <a href={url} target="_blank" rel="noreferrer">{name}</a>
  }
}

export const IdecoItems = (props) => {
  const urlQuery = new URLQuery()
  const company = props.companies.find(c => c.id === Number(urlQuery.queryC))
  const { items, requestSort } = UseSortableData(filterItems(props.items, company, urlQuery.queryF))

  const filterAreaStyle = {
    display: 'flex',
    flexDiretion: 'row'
  }
  const headerKeys = {
    name: '銘柄',
    group: '分類',
    cost: '経費率',
    company: '証券会社'
  }
  const filters = [null, 'r1', 'r2', 'r3']
  let title = 'iDeCo銘柄一覧'
  if (company !== undefined) {
    title = `${company.name}の${title}`
  }
  return (
    <>
      <header className="main">
        <h1>{title}</h1>
      </header>
      <hr className="major" />
      <GroupChart items={props.items} company={company}/>
      <div style={filterAreaStyle}>
        <div>
          <p>絞り込み：</p>
        </div>
        {
          filters.map((filter) => {
            const count = filterItems(props.items, company, filter).length
            return customFilterLink(filter, count, urlQuery)
          })
        }
      </div>
      <table>
      <thead>
        <tr>
        {
          Object.keys(headerKeys).map((key) => {
            return (
              <th key={key} onClick={() => requestSort(key)} style={thStyle}>
                {headerKeys[key]}<i className="fa fa-sort"></i>
              </th>
            )
          })
        }
        </tr>
      </thead>
      <tbody>
      {
        items.map((item) => {
          return (
            <tr key={item.name + item.company}>
              <td style={nameStyle}>
                {itemLink(item.name, item.url)}
              </td>
              <td style={defaultStyle}>{item.group}</td>
              {
                item.cost === InvalidSortValue ? <td style={defaultStyle}>{item.cost}</td> : <td style={costStyle}>{item.cost}%</td>
              }
              <td style={companyStyle}>{item.company}</td>
            </tr>
          )
        })
      }
      </tbody>
      </table>
    </>
  )
}
