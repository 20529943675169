import React from 'react'
import { Route, Link } from 'react-router-dom'
import { InvalidSortValue } from './Params'

// sort参考：https://www.smashingmagazine.com/2020/03/sortable-tables-react/
export const UseSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config)

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let va = a[sortConfig.key]
        let vb = b[sortConfig.key]
        // コスト値が決まっていないようなものも、載せつつソートとしては最大値として扱う
        if (va === InvalidSortValue) {
          va = Number.MAX_SAFE_INTEGER
        }
        if (vb === InvalidSortValue) {
          vb = Number.MAX_SAFE_INTEGER
        }
        if (va < vb) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (va > vb) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (
      sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  return { items: sortedItems, requestSort }
}

export const CustomLink = ({ children, exact, to }) => (
    <Route
      path={to}
      exact={exact}
      // eslint-disable-next-line react/no-children-prop
      children={({ match }) => (
        <div>
          {match ? children : <Link to={to}>{children}</Link>}
        </div>
      )}
    />
)
