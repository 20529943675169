import React from 'react'
import { GroupParams } from '../Params'
import { Chart, Radar } from 'react-chartjs-2'

const createRadarGraphData = (items, company) => {
  const groups = GroupParams.map(x => x.value)
  const counts = groups.map(group => items.filter(i => i.group === group).length)
  Chart.defaults.global.legend.position = 'bottom'

  if (company !== undefined) {
    items = items.filter(x => x.company === company.name)
  }
  const data = {
    labels: groups,
    datasets: [
      {
        data: counts,
        backgroundColor: 'rgba(30, 200, 145, 0.3)',
        borderColor: 'rgba(30, 200, 145, 0.7)',
        label: '銘柄数',
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: 'rgb(255, 99, 132)'
            }
          }
        }
      }
    ],
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
  return data
}

export const GroupChart = (props) => {
  if (props.company === undefined) {
    return <></>
  } else {
    return (
      <>
        <Radar data={createRadarGraphData(props.items, props.company)} />
        <hr className="major" />
      </>
    )
  }
}
