import React from 'react'
import './App.css'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { Main } from './components/Main'

// Firebaseの設定
const firebaseConfig = {
  apiKey: 'AIzaSyDo1hIDcMHCX4ipGyYlRAtUjhgrC-ebr8I',
  authDomain: 'ring-cbd4f.firebaseapp.com',
  databaseURL: 'https://ring-cbd4f-default-rtdb.firebaseio.com',
  projectId: 'ring-cbd4f',
  storageBucket: 'ring-cbd4f.appspot.com',
  messagingSenderId: '891313947598',
  appId: '1:891313947598:web:482637cb902fac4fd5a19f',
  measurementId: 'G-3GTKSPJQ4W'
}

// Firebaseの初期化
firebase.initializeApp(firebaseConfig)

export default function App () {
  return (
    <>
      <div className="App">
        <Main />
      </div>
    </>
  )
}
