import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

import { IdecoItems } from './IdecoItems'
import { Companies } from './Companies'
import { InvalidSortValue } from '../Params'

export class Main extends Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [],
      companies: []
    }
  }

  componentDidMount () {
    this.getFireDataIfNeeded()
  }

  // firebaseのデータを元に情報を更新
  getFireDataIfNeeded () {
    if (this.state.items.length > 0) {
      return
    }
    const db = firebase.database()
    db.ref('ideco/items/').orderByKey()
      .limitToFirst(1000)
      .on('value', snapshot => {
        this.setState({
          items: this.fireDataToItems(snapshot.val())
        })
      })
    db.ref('ideco/companies/').orderByKey()
      .limitToFirst(50)
      .on('value', snapshot => {
        this.setState({
          companies: this.fireDataToCompanies(snapshot.val())
        })
      })
  }

  // firebaseデータを元にitemsを生成
  fireDataToItems (fireData) {
    const items = fireData
      .filter(x => x !== Object.prototype)
      .map((item) => {
        const hash = {
          name: item.name,
          group: item.group,
          cost: item.cost,
          company: item.company,
          url: item.url
        }
        return hash
      })
    // cost基準ソートをデフォとする
    items.sort((a, b) => {
      const key = 'cost'
      let va = a[key]
      let vb = b[key]
      if (va === InvalidSortValue) {
        va = Number.MAX_SAFE_INTEGER
      }
      if (vb === InvalidSortValue) {
        vb = Number.MAX_SAFE_INTEGER
      }
      if (va < vb) return -1
      if (va > vb) return 1
      return 0
    })
    return items
  }

  // firebaseデータを元にcompaniesを生成
  fireDataToCompanies (fireData) {
    const items = fireData
      .filter(x => x !== Object.prototype)
      .map((company) => {
        const hash = {
          id: company.id,
          name: company.name
        }
        return hash
      })
    return items
  }

  render () {
    if (this.state.items.length === 0) {
      return <div></div>
    }
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' render={() => <IdecoItems items={this.state.items} companies={this.state.companies} />} />
            <Route path='/c' render={() => <Companies items={this.state.items} companies={this.state.companies} />} />
          </Switch>
        </BrowserRouter>
      </>
    )
  }
};
