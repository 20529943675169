import { useLocation } from 'react-router-dom'

export function URLQuery () {
  const query = new URLSearchParams(useLocation().search)
  this.queryF = query.get('f')
  this.queryC = query.get('c')
}

URLQuery.prototype.url = function (queryF, queryC) {
  let url = '/'
  if (queryF != null) {
    url = `/?f=${queryF}`
  }
  if (queryC !== null) {
    url += url === '/' ? '?' : '&'
    url += `c=${queryC}`
  }
  return url
}
